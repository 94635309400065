import { api } from '../api';
import { Dish } from 'store/dishes/types';
import { EditingDishType } from 'store/dishes';
import { NewComplex } from 'store/complexes';

export type Paginatable<Data> = {
    current_page: number;
    from: number;
    last_page: number;
    data: Data;
};

const dishesApi = api.injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
        getDishes: builder.query<
            Paginatable<Dish[]>,
            {
                page: number;
                category?: string;
                search?: string;
                perPage?: number;
                initial?: boolean;
                activeOnly?: boolean;
            }
        >({
            query: ({ page, category, search, perPage = 50, initial, activeOnly }) => {
                const params: Record<string, string | number | boolean> = {
                    'per_page': perPage,
                    page,
                    'sort[created_at]': 'desc',
                };
                if (initial) {
                    params.initial = true;
                }
                if (activeOnly) {
                    params['filter[status]'] = '1';
                }
                if (category) {
                    params['filter[category_id]'] = category;
                }
                if (search) {
                    params['search'] = search;
                }
                return {
                    url: '/app/dishes',
                    params,
                };
            },
            providesTags: ['Dishes'],
        }),
        getDishesByCategory: builder.query<
            Dish[],
            { category: string; search?: string; initial?: boolean; activeOnly?: boolean }
        >({
            query: ({ category, search, initial, activeOnly }) => {
                const params: Record<string, string | number | boolean> = {
                    'per_page': 'all',
                    'sort[created_at]': 'desc',
                    'filter[category_id]': category,
                };
                if (initial) {
                    params.initial = true;
                }
                if (activeOnly) {
                    params['filter[status]'] = '1';
                }
                if (search) {
                    params['search'] = search;
                }
                return {
                    url: '/app/dishes',
                    params,
                };
            },
            providesTags: ['Dishes'],
        }),
        updateDish: builder.mutation<void, EditingDishType | NewComplex>({
            //@ts-ignore
            query: ({ id, active, saving, ...item }) => {
                return {
                    body: item,
                    method: 'PUT',
                    url: `/app/dishes/${id}`,
                };
            },
            invalidatesTags: ['Dishes'],
        }),
        newDish: builder.mutation<void, EditingDishType | NewComplex>({
            //@ts-ignore
            query: ({ id, active, saving, ...item }) => {
                return {
                    body: item,
                    method: 'POST',
                    url: `/app/dishes`,
                };
            },
            invalidatesTags: ['Dishes'],
        }),
        deleteDish: builder.mutation<void, string>({
            query: id => {
                return {
                    method: 'DELETE',
                    url: `/app/dishes/${id}`,
                };
            },
            invalidatesTags: ['Dishes'],
        }),
        addDishImage: builder.mutation<any, { id: string; body: FormData }>({
            query: ({ id, body }) => {
                return {
                    method: 'POST',
                    body,
                    defaultHeaders: true,
                    url: `/app/dishes/${id}/image`,
                };
            },
            invalidatesTags: ['Dishes'],
        }),
        removeDishImage: builder.mutation<any, { id: string }>({
            query: ({ id }) => {
                return {
                    method: 'DELETE',
                    url: `/app/dishes/${id}/image`,
                };
            },
            invalidatesTags: ['Dishes'],
        }),
    }),
});

export const {
    useGetDishesQuery,
    useUpdateDishMutation,
    useNewDishMutation,
    useDeleteDishMutation,
    useAddDishImageMutation,
    useRemoveDishImageMutation,
    useGetDishesByCategoryQuery,
} = dishesApi;
