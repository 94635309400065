import { useMemo } from 'react';
import { useAppSelector } from 'store/index';
import { Dish } from 'store/dishes/types';

export const useNewDish = () => {
    return useAppSelector(({ dishes: { new: newDish } }) => newDish);
};

export const useDishesByCategory = (category: string) => {
    return useAppSelector(({ dishes: { data } }) => {
        if (!category) return data;
        return data.filter(dish => dish.category_id == category) || [];
    });
};

export const useDishesLoading = () => {
    return useAppSelector(({ dishes: { loading } }) => loading);
};

export const useDishesUpdating = () => {
    return useAppSelector(({ dishes: { updating } }) => updating);
};

export const useFilteredDishes = () => {
    return useAppSelector(({ dishes: { data, filtered } }) => {
        return filtered || data;
    });
};

export const useDish = (id?: string) => {
    return useAppSelector(({ dishes: { data } }) => {
        if (!id) return null;
        return data.find(dish => dish.id == id) || null;
    });
};

export const useDishes = (limit?: number) => {
    const dishes = useAppSelector(({ dishes: { data } }) => data);

    return useMemo(() => {
        if (!limit) return dishes;
        return dishes.slice(0, limit);
    }, [limit, dishes]);
};

export const useEditingImage = () => {
    return useAppSelector(({ dishes }) => dishes.imageEditing);
};

export const useSearchQuery = () => {
    return useAppSelector(({ dishes }) => dishes.searchQuery);
};

export const useSearchCategory = () => {
    return useAppSelector(({ dishes }) => dishes.searchCategory);
};
