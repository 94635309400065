import styles from './timeBlock.module.css';
import { Clock } from '../../icons';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { FunctionComponent } from 'react';
import { Text } from 'components/text';

export const TimeBlock: FunctionComponent<{
    from: string;
    to: string;
    noTime?: boolean;
}> = ({ from: fromRaw, to: toRaw, noTime }) => {
    const from = parseISO(fromRaw);
    const to = parseISO(toRaw);

    return (
        <div className={styles.timeBlock}>
            {/*<div className={styles.timeBlock__block}>*/}
            {/*    {!noTime ? (*/}
            {/*        <div className={styles.timeBlock__time}>*/}
            {/*            {format(from, 'HH:mm')}*/}
            {/*        </div>*/}
            {/*    ) : (*/}
            {/*        ''*/}
            {/*    )}*/}
            {/*    <div className={styles.timeBlock__date}>*/}
            {/*        {format(from, 'dd.LL.yyyy')}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.timeBlock__separator}>
                {/*<Clock />*/}
                <Text k={'Order until'} />:
            </div>
            <div className={styles.timeBlock__block}>
                {!noTime ? (
                    <div className={styles.timeBlock__time}>
                        {format(to, 'HH:mm')}
                    </div>
                ) : (
                    ''
                )}
                <div className={styles.timeBlock__date}>
                    {format(to, 'dd.LL.yyyy')}
                </div>
            </div>
        </div>
    );
};
