import { Text } from 'components/text';
import { useNewCategory } from 'store/categories/selectors';
import { resetNewCategory, updateNewCategory } from 'store/categories/actions';
import { useCategoriesRefresh } from 'hooks/api/useCategoriesApi';
import { EditModal } from 'components/common/editModal';

export const NewCategoryModal = () => {
    const newCategory = useNewCategory();
    const refreshCategories = useCategoriesRefresh();

    return (
        <EditModal
            title={<Text k={`New Category`} />}
            update={updateNewCategory}
            reset={resetNewCategory}
            item={newCategory}
            fieldIdPrefix={'category'}
            refresh={refreshCategories}
            url={'/categories'}
            fields={[
                {
                    type: 'multilingual',
                    key: 'title',
                    label: <Text k={`Category Title`} />,
                },
                {
                    type: 'checkbox',
                    key: 'status',
                    label: <Text k={`Active`} />,
                },
                {
                    type: 'checkbox',
                    key: 'simple',
                    label: <Text k={`Simple`} />,
                },
                {
                    type: 'input',
                    key: 'group_name',
                    label: <Text k={`Group Name`} />,
                },
                {
                    type: 'input',
                    key: 'position',
                    label: <Text k={`Position`} />,
                    props: {
                        type: 'number',
                    },
                    shouldRender: item => item['id'],
                },
            ]}
            formId={'new-category-form'}
        />
    );
};
