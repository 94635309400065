import { Button } from 'components/common/button';
import {
    Balance,
    Bin,
    Copy,
    Disabled,
    Edit,
    TickFilled,
} from 'components/icons';
import { Text } from 'components/text';
import { DataTable } from 'components/common/dataTable';
import { Loader } from 'components/common/loader';
import { useMenus, useMenusLoading } from 'store/menus/selectors';
import format from 'date-fns/format';
import { useMenusRefresh } from 'hooks/api/useMenusApi';
import { useApi } from 'hooks/useApi';
import { useTranslation } from 'store/dictionary/selectors';

export const MenusTable = ({ onDelete }) => {
    const menus = useMenus();
    const deleter = useApi('/dishlists', { method: 'delete' });
    const refreshMenus = useMenusRefresh();
    const loading = useMenusLoading();
    const balanceTitle = useTranslation(`Edit balance`);

    const handleDelete = async id => {
        onDelete(true);
        const res = await deleter({ route: `/${id}` });
        const data = await res.json();
        if (data.success) {
            await refreshMenus();
        }
        onDelete(false);
    };

    if (loading) return <Loader />;
    return (
        <DataTable
            placeholderAction={
                <Button to={'/menu/new'}>
                    <Text k={`Try adding new Menu`} />
                </Button>
            }
            fields={[
                {
                    label: <Text k={`Title`} />,
                    key: 'title',
                },
                {
                    label: <Text k={`From`} />,
                    key: 'from',
                    processor: value => {
                        return format(new Date(value), 'dd.LL.yy HH:mm');
                    },
                },
                {
                    label: <Text k={`To`} />,
                    key: 'to',
                    processor: value => {
                        return format(new Date(value), 'dd.LL.yy HH:mm');
                    },
                },
                {
                    label: <Text k={`Active`} />,
                    key: 'status',
                    processor: value => (value ? <TickFilled /> : <Disabled />),
                },
            ]}
            itemActions={[
                {
                    icon: <Copy />,
                    to: item => `/menu/${item.id}?duplicate=true`,
                },
                {
                    icon: <Edit />,
                    to: item => `/menu/${item.id}`,
                },
                {
                    icon: <Balance />,
                    title: balanceTitle,
                    to: item => `/menu/${item.id}/balance`,
                },
                {
                    icon: <Bin />,
                    onClick: item => handleDelete(item.id),
                },
            ]}
            data={menus}
        />
    );
};
