import styles from './pagination.module.css';
import { ActionGroup } from 'components/common/actionGroup';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSearchParam } from 'hooks/useSearchParam';
import { Button } from 'components/common/button';
import { Left, Right } from 'components/icons';
import { FunctionComponent } from 'react';

interface CustomPaginationProps {
    current: number;
    total: number;
}

export const CustomPagination: FunctionComponent<CustomPaginationProps> = ({ current, total }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = useSearchParam('page');

    const pageNumber = page ? parseInt(page) : 1;

    const setPage = (page: number) => {
        setSearchParams({ page: page.toString() });
    };

    const getPages = () => {
        const pages = [];
        for (let i = 1; i <= total; i++) {
            pages.push(
                <Button key={i} small onClick={() => setPage(i)} secondary={i !== pageNumber}>
                    {i}
                </Button>
            );
        }
        return pages;
    };

    return (
        <div className={styles.pagination}>
            <ActionGroup center>
                <Button
                    onClick={() => setPage(1)}
                    iconOnly
                    icon={<Left />}
                    secondary
                    disabled={pageNumber <= 1}
                    small
                />
                {getPages()}
                <Button
                    onClick={() => setPage(Math.min(current + 1, total + 1))}
                    iconOnly
                    icon={<Right />}
                    secondary
                    disabled={pageNumber >= total + 1}
                    small
                />
            </ActionGroup>
        </div>
    );
};
