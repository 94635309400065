import { useState } from 'react';
import { ActionGroup } from 'components/common/actionGroup';
import { Input } from 'components/common/input';
import { useDispatch } from 'react-redux';
import { setSearchCategory, setSearchQuery } from 'store/dishes/actions';
import { useApi } from 'hooks/useApi';
import { useTranslation } from 'store/dictionary/selectors';
import { CategorySelect } from 'components/pages/categories/categorySelect';
import { FilterCard } from 'components/common/filterCard';
import { useProviderId } from 'hooks/useProviderId';
import { useSearchCategory, useSearchQuery } from 'store/dishes/selectors';

export const DishFilterCard = () => {
    const dispatch = useDispatch();
    // const [category, setCategory] = useState('');
    // const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const providerId = useProviderId();
    const request = useApi('/dishes', { params: { provider_id: providerId } });
    const filterPlaceholder = useTranslation(`Filter...`);

    const search = useSearchQuery();
    const category = useSearchCategory();

    // const params = useMemo(() => {
    //     if (!(searchQuery || category)) return null;
    //     const params = {};
    //     if (category) {
    //         params['filter[category_id]'] = category;
    //     }
    //     if (searchQuery) {
    //         params['search'] = searchQuery;
    //     }
    //     return params;
    // }, [searchQuery, category]);

    // useEffect(() => {
    //     if (params) {
    //         const controller = new AbortController();
    //         const signal = controller.signal;
    //
    //         const requestSearch = async () => {
    //             setLoading(true);
    //             try {
    //                 const res = await request({
    //                     params,
    //                     signal,
    //                 });
    //                 const result = await res.json();
    //                 dispatch(setFilteredDishes(result.data));
    //             } catch (error) {
    //                 if (error.name !== 'AbortError') {
    //                     console.error(error);
    //                 }
    //             }
    //             setLoading(false);
    //         };
    //         requestSearch();
    //         return () => controller.abort();
    //     } else {
    //         dispatch(setFilteredDishes(null));
    //     }
    // }, [params, dispatch]);

    return (
        <FilterCard
            tinyPadding
            loading={loading}
            isSet={search || category}
            onReset={() => {
                dispatch(setSearchQuery(''));
                dispatch(setSearchCategory(''));
            }}
        >
            <div>
                <ActionGroup>
                    <Input
                        id={'dishes-search'}
                        placeholder={filterPlaceholder}
                        value={search}
                        onChange={event =>
                            dispatch(setSearchQuery(event.target.value))
                        }
                    />
                    <CategorySelect
                        id={`dish-filter-category`}
                        value={category}
                        onChange={event =>
                            dispatch(setSearchCategory(event.target.value))
                        }
                    />
                </ActionGroup>
            </div>
        </FilterCard>
    );
};
