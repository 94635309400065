import { useAppSelector } from 'store/index';

export const useNewLocation = () => {
    return useAppSelector(({ locations: { new: newLocation } }) => newLocation);
};

export const useLocationsSearchQuery = () => {
    return useAppSelector(({ locations: { searchQuery } }) => searchQuery);
};

// export const useLocations = () => {
//     return useSelector(({ locations: { data } }) => data);
// };
//
// export const useLocationById = id => {
//     return useSelector(({ locations: { data } }) => (data ? data.find(location => location.id === id) : undefined));
// };
//
// export const useLocationsLoading = () => {
//     return useSelector(({ locations: { loading } }) => loading);
// };
//
// export const useFilteredLocations = () => {
//     return useSelector(({ locations: { filtered } }) => filtered);
// };
