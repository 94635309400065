import { ContentBlock } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { ActionGroup } from 'components/common/actionGroup';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { useSearchParam } from 'hooks/useSearchParam';
import { useCallback, useEffect, useMemo } from 'react';
import {
    setOrdersByMenu,
    setOrdersByMenuLoading,
    setOrdersByMenuUpdating,
} from 'store/ordersByMenu/actions';
import { useApi } from 'hooks/useApi';
import { useDispatch } from 'react-redux';
import { useOrdersByMenuUpdating } from 'store/ordersByMenu/selectors';
import { useParams } from "react-router-dom";

export const PageControls = () => {
    const dispatch = useDispatch();
    const { menuId } = useParams();
    const accepted = useSearchParam('accepted');

    const updating = useOrdersByMenuUpdating();
    const getOrders = useApi('/order');

    const params = useMemo(() => {
        const params = {
            'filter[dishlist_id]': menuId,
            'filter[status]': '2',
            'per_page': 'all',
            'full': '1',
        };
        if (accepted !== undefined) {
            params['filter[accepted]'] = accepted;
        }
        return params;
    }, [accepted, menuId]);

    const refresh = useCallback(
        async updating => {
            if (updating) {
                dispatch(setOrdersByMenuUpdating(true));
            } else {
                dispatch(setOrdersByMenuLoading(true));
            }
            const res = await getOrders({ params });
            dispatch(setOrdersByMenu(await res.json()));
            if (updating) {
                dispatch(setOrdersByMenuUpdating(false));
            }
        },
        [dispatch, params, getOrders]
    );

    useEffect(() => {
        refresh();
    }, [accepted, refresh]);

    // useEffect(() => {
    //     const interval = setInterval(() => refresh(true), 30000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [refresh]);

    return (
        <ContentBlock>
            <ContentCard tinyPadding loading={updating}>
                <ActionGroup>
                    <Button
                        secondary={accepted !== 0}
                        to={`/orders/${menuId}?accepted=0`}
                    >
                        <Text k={`New`} />
                    </Button>
                    <Button
                        secondary={accepted !== 1}
                        to={`/orders/${menuId}?accepted=1`}
                    >
                        <Text k={`Accepted`} />
                    </Button>
                    <Button
                        secondary={accepted !== undefined}
                        to={`/orders/${menuId}`}
                    >
                        <Text k={`All`} />
                    </Button>
                </ActionGroup>
            </ContentCard>
        </ContentBlock>
    );
};
