import { useState, useEffect } from 'react';
import styles from './newMenu.module.css';
import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { Text } from 'components/text';
import { useDishesApi } from 'hooks/api/useDishesApi';
import { useCategoriesApi } from 'hooks/api/useCategoriesApi';
import { NewMenuForm } from 'components/pages/newMenu/newMenuForm';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { NewMenu } from 'components/pages/newMenu/newMenu';
import { useApi } from 'hooks/useApi';
import { Loader } from 'components/common/loader';
import { useDispatch } from 'react-redux';
import { setNewMenu } from 'store/newMenu/actions';
import { useDishesLoading } from 'store/dishes/selectors';
import { useCategoriesLoading } from 'store/categories/selectors';
import format from 'date-fns/format';
import { Button } from 'components/common/button';
import { Left } from 'components/icons';
import { HeaderBlock } from 'components/common/headerBlock';
import { useSearchParam } from 'hooks/useSearchParam';
import {ErrorBoundary} from "components/common/errorBoudry";
import { useParams } from "react-router-dom";

export const NewMenuPage = () => {
    const dispatch = useDispatch();
    const { menuId } = useParams();
    const duplicate = useSearchParam('duplicate');
    useCategoriesApi();
    const getMenu = useApi('/dishlists');
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const categoriesLoading = useCategoriesLoading();

    useEffect(() => {
        if (menuId) {
            const requestMenu = async () => {
                const res = await getMenu({ route: `/${menuId}` });
                if (res && res.ok) {
                    const { id, ...menu } = await res.json();
                    menu.from = format(
                        new Date(menu.from),
                        `yyyy-LL-dd'T'HH:mm`
                    );
                    menu.to = format(new Date(menu.to), `yyyy-LL-dd'T'HH:mm`);
                    if (!duplicate) {
                        menu.id = id;
                    }
                    dispatch(setNewMenu(menu));
                    setLoading(false);
                }
            };
            requestMenu();
        }
    }, [getMenu, menuId, dispatch, setLoading, duplicate]);

    return (
        <ErrorBoundary boundary="new-menu">
            <ContentGrid className={styles.newMenu}>
                <ContentBlock>
                    <HeaderBlock
                        action={
                            menuId && !duplicate ? (
                                <Button
                                    icon={<Left />}
                                    small
                                    secondary
                                    to={`/menu`}
                                >
                                    <Text k={`Back`} />
                                </Button>
                            ) : (
                                <Button
                                    icon={<Left />}
                                    small
                                    secondary
                                    to={`/menu`}
                                >
                                    <Text k={`Back`} />
                                </Button>
                            )
                        }
                        className={`shaded`}
                    >
                        <h1 className={`h1 h1_noMargin`}>
                            {menuId && !duplicate ? (
                                <Text k={`Edit Menu`} />
                            ) : (
                                <Text k={`New Menu`} />
                            )}
                        </h1>
                    </HeaderBlock>
                </ContentBlock>
                <ContentBlock span={7} className={styles.newMenu__menu}>
                    <ContentCard className={styles.newMenu__menuCard}>
                        {((menuId && !loading) || !menuId) &&
                        !(categoriesLoading) ? (
                            <NewMenu />
                        ) : (
                            <Loader />
                        )}
                    </ContentCard>
                </ContentBlock>
                <ContentBlock span={5} className={styles.newMenu__selector}>
                    <ContentCard loading={loadingFilter} fixedHeight>
                        <NewMenuForm onLoading={setLoadingFilter} />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
        </ErrorBoundary>
    );
};
