import throttle from 'lodash.throttle';
import { loadState, saveState } from '../helpers/localStorage';

import { commonReducer, initialState as commonInitialState } from './common';
import { navReducer } from './nav';
import { loginReducer } from './login';
import { userReducer } from './user';
import { categoriesReducer } from './categories';
import { dishesReducer } from 'store/dishes';
import { complexesReducer } from 'store/complexes';
import { menuReducer } from 'store/menu';
import { providersReducer } from 'store/providers';
import { historyReducer } from 'store/history';
import { menuHistoryReducer } from 'store/menuHistory';
import { orderHistoryReducer } from 'store/orderHistory';
import { dictionaryReducer } from 'store/dictionary';
import { teamReducer } from 'store/team';
import { newMenuReducer } from 'store/newMenu';
import { menusReducer } from 'store/menus';
import { ordersByMenuReducer } from 'store/ordersByMenu';
import { configureStore } from '@reduxjs/toolkit';
import { balanceReducer } from 'store/balance';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { kdsReducer } from 'store/kds';
import { userListReducer } from 'store/userList';
import { lapsReducer } from 'store/laps';
import { lapReducer } from 'store/lap';
import { reviewReducer } from 'store/review';
import {
    expandedMenuInitialState,
    expandedMenuReducer,
} from 'store/expandedMenu';
import { publicMenuReducer } from 'store/publicMenu';
import { api } from '../api/api';
import { rtkQueryErrorLogger } from '../api/middleware';
import {locationsSlice} from "store/locations/slice";

let persistedState = loadState();

if (persistedState) {
    persistedState = {
        ...persistedState,
        common: { ...commonInitialState, ...persistedState.common },
        expandedMenu: {
            ...expandedMenuInitialState,
            ...persistedState.expandedMenu,
        },
    };
} else {
    persistedState = {};
}

export const store = configureStore({
    reducer: {
        common: commonReducer,
        nav: navReducer,
        login: loginReducer,
        user: userReducer,
        categories: categoriesReducer,
        dictionary: dictionaryReducer,
        dishes: dishesReducer,
        complexes: complexesReducer,
        menu: menuReducer,
        locations: locationsSlice.reducer,
        providers: providersReducer,
        history: historyReducer,
        menuHistory: menuHistoryReducer,
        orderHistory: orderHistoryReducer,
        team: teamReducer,
        newMenu: newMenuReducer,
        menus: menusReducer,
        ordersByMenu: ordersByMenuReducer,
        balance: balanceReducer,
        kds: kdsReducer,
        userList: userListReducer,
        laps: lapsReducer,
        lap: lapReducer,
        review: reviewReducer,
        expandedMenu: expandedMenuReducer,
        publicMenu: publicMenuReducer,
        [api.reducerPath]: api.reducer,
    },
    preloadedState: persistedState,
    devTools: true,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(api.middleware)
            .concat(rtkQueryErrorLogger),
});

store.subscribe(
    throttle(() => {
        const { loading, ...common } = store.getState().common;
        const expandedMenu = store.getState().expandedMenu;
        saveState({
            common,
            expandedMenu,
        });
    }, 200)
);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
