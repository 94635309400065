import { useApiData } from '../useApiData';
import {
    setAvailableMenus,
    setMenu,
    setMenuUpdating,
} from 'store/menu/actions';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/useApi';
import { useEffect } from 'react';

export const useMenuApi = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setMenuUpdating(true));
    }, [dispatch]);
    useApiData('/order/create', setMenu);
};

export const useAvailableMenusApi = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setMenuUpdating(true));
    }, [dispatch]);
    useApiData('/order/dishlists', setAvailableMenus);
};

export const useMenuRefresh = () => {
    const dispatch = useDispatch();
    const getMenus = useApi('/order/create');

    return async () => {
        dispatch(setMenuUpdating(true));
        const res = await getMenus();
        dispatch(setMenu(await res.json()));
    };
};
