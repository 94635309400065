import { useTranslator } from 'hooks/useTranslator';
import { useTranslation } from 'store/dictionary/selectors';
import { useDishesByCategory } from 'store/dishes/selectors';
import { SearchableSelect } from 'components/common/searchableSelect';
import { FunctionComponent, useMemo } from 'react';
import { Dish } from 'store/dishes/types';
import { DishType } from 'constants/dishType';
import { useGetDishesByCategoryQuery } from '../../../../api/dishes/endpoints';
import { Loader } from 'components/common/loader';

export const DishSelect: FunctionComponent<{
    value: string;
    dishOnly?: boolean;
    category: string;
}> = ({ category, value, dishOnly, ...rest }) => {
    const translator = useTranslator();
    const emptyLabel = useTranslation(`Select a dish...`);
    const { data: dishes } = useGetDishesByCategoryQuery({ category: category, initial: dishOnly });

    const filteredDishes = useMemo(() => {
        if (!dishes) return [];
        if (!dishOnly) {
            return dishes;
        }
        return dishes.filter(dish => dish.type !== DishType.complex);
    }, [dishes, dishOnly]);

    const options: { value: string; label: string }[] = filteredDishes.map(({ id, title }) => ({
        value: id,
        label: translator(title),
    }));

    const option = options.find(option => option.value === value);

    if (!dishes) return <Loader />;
    return <SearchableSelect value={option} placeholder={emptyLabel} options={options} grow {...rest} />;
};
