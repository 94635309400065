import {
    useCategories,
    useCategoriesLoading,
} from 'store/categories/selectors';
import { useTranslator } from 'hooks/useTranslator';
import { Button } from 'components/common/button';
import { Bin, Disabled, Edit, TickFilled } from 'components/icons';
import { Text } from 'components/text';
import { useDispatch } from 'react-redux';
import { setNewCategory, updateNewCategory } from 'store/categories/actions';
import { useApi } from 'hooks/useApi';
import { useCategoriesRefresh } from 'hooks/api/useCategoriesApi';
import { DataTable } from 'components/common/dataTable';
import { Loader } from 'components/common/loader';

export const CategoriesTable = () => {
    const dispatch = useDispatch();
    const categories = useCategories();
    const translator = useTranslator();
    const deleter = useApi('/categories', { method: 'delete' });
    const refreshCategories = useCategoriesRefresh();
    const loading = useCategoriesLoading();

    const handleDelete = async id => {
        const res = await deleter({ route: `/${id}` });
        const data = await res.json();
        if (data.success) {
            await refreshCategories();
        }
    };

    if (loading) return <Loader />;
    return (
        <DataTable
            placeholderAction={
                <Button
                    onClick={() =>
                        dispatch(updateNewCategory({ active: true }))
                    }
                >
                    <Text k={`Try adding new Category`} />
                </Button>
            }
            fields={[
                {
                    label: <Text k={`Position`} />,
                    key: 'position',
                },
                {
                    label: <Text k={`Category Title`} />,
                    key: 'title',
                    processor: value => translator(value),
                },
                {
                    label: <Text k={`Active`} />,
                    key: 'status',
                    processor: value => (value ? <TickFilled /> : <Disabled />),
                },
            ]}
            itemActions={[
                {
                    icon: <Edit />,
                    onClick: item =>
                        dispatch(
                            setNewCategory({
                                ...item,
                                active: true,
                            })
                        ),
                },
                {
                    icon: <Bin />,
                    onClick: item => handleDelete(item.id),
                },
            ]}
            data={categories}
        />
    );
};
