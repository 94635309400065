import { useSelector } from 'react-redux';

export const useUser = () => {
    return useSelector(({ user }) => user);
};

export const useUserId = () => {
    return useSelector(({ user: { id } }) => id);
};

export const useUserField = key => {
    const originalValue = useSelector(({ user }) => user[key]);
    const value = useSelector(({ user: { edited } }) => edited[key]);
    if (value === undefined || value === null) {
        return originalValue || '';
    }
    return value;
};

export const useUserFieldEdited = key => {
    const originalValue = useSelector(({ user }) => user[key]);
    const value = useSelector(({ user: { edited } }) => edited[key]);
    if (value === undefined || value === null) {
        return false;
    }
    return value !== originalValue;
};

export const useUserFieldChanges = () => {
    return useSelector(({ user: { edited } }) => edited);
};

export const useLocationId = () => {
    return useSelector(({ user: { location_id } }) => location_id);
};

export const useUserLocation = () => {
    return useSelector(({ user: { location } }) => location);
};

export const useUserProviderId = () => {
    return useSelector(({ user: { provider_id } }) => provider_id);
};

export const useUserIsCustomer = () => {
    return useSelector(({ user: { is_customer } }) => is_customer);
};
