import { useAvailableDishlists, useMenuDuration, useSelectedDishlist } from 'store/menu/selectors';
import { TimeBlock } from 'components/order/timeBlock/timeBlock';
import { Select } from 'components/common/select';
import styles from './newOrderHeader.module.css';
import { useDispatch } from 'react-redux';
import { setSelectedDishlist } from 'store/menu/actions';
import { useGetHeaderInfoQuery } from '../../../../api/headerInfo/endpoints';
import { useTranslator } from 'hooks/useTranslator';
import { Loader } from 'components/common/loader';

export const NewOrderHeader = () => {
    const dispatch = useDispatch();
    const { from, to } = useMenuDuration();
    const availableDishlists = useAvailableDishlists();
    const selectedDishlist = useSelectedDishlist();
    const translator = useTranslator();

    const { data } = useGetHeaderInfoQuery();

    if (!from || !to || !availableDishlists) return null;
    return (
        <div className={styles.newOrderHeader__row}>
            <Select
                value={selectedDishlist || ''}
                onChange={event => {
                    dispatch(setSelectedDishlist(event.target.value));
                }}
                options={availableDishlists.map(dishlist => ({
                    key: dishlist.id,
                    label: `${dishlist.title}`,
                }))}
            />
            <div className={styles.newOrderHeader__infoRow}>
                <TimeBlock from={from} to={to} />
                {data?.text ? (
                    <div
                        className={`text ${styles.newOrderHeader__info}`}
                        dangerouslySetInnerHTML={{
                            __html: translator(data.text),
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};
