import { useOrdersByMenuLocations } from 'store/ordersByMenu/selectors';
import { ContentBlock } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { Text } from 'components/text';
import React, { useState } from 'react';
import { LocationGroup } from 'components/pages/ordersByMenu/locationGroup';
import { FilterCard } from 'components/common/filterCard';
import { useTranslation } from 'store/dictionary/selectors';
import { SearchableSelect } from 'components/common/searchableSelect';

export const OrderList = () => {
    const locations = useOrdersByMenuLocations();

    const emptyLabel = useTranslation(`Select specific location...`);
    const [location, setLocation] = useState<{ value: string; label: string } | null>(null);

    const options: { value: string; label: string }[] = locations.map(location => {
        return {
            value: location.id,
            label: location.title,
        };
    });

    const renderGroups = () => {
        if (!locations.length) {
            return (
                <>
                    <ContentBlock>
                        <ContentCard center title={<Text k={`No orders for this menu`} />} />
                    </ContentBlock>
                </>
            );
        }
        if (location) {
            return <LocationGroup id={location.value} />;
        }

        return locations.map((locationDetails, index) => {
            return <LocationGroup id={locationDetails.id} key={locationDetails.id} />;
        });
    };

    return (
        <>
            <ContentBlock>
                <FilterCard
                    isSet={location}
                    onReset={() => {
                        setLocation(null);
                    }}
                >
                    <SearchableSelect
                        placeholder={emptyLabel}
                        value={location}
                        //@ts-ignore
                        onChange={option => setLocation(option)}
                        options={options}
                    />
                </FilterCard>
            </ContentBlock>
            {renderGroups()}
        </>
    );
};
