import { ContentBlock } from 'components/layout/contentGrid';
import styles from './locationGroup.module.css';
import { LocationTitle } from 'components/common/locationTitle';
import { SumCard } from 'components/common/sumCard';
import { Button } from 'components/common/button';
import { Text } from 'components/text';
import { useCallback, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { useFileDownloader } from 'hooks/useFileDownloader';
import { useSearchParam } from 'hooks/useSearchParam';
import { useOrdersByMenuLocationGroup } from 'store/ordersByMenu/selectors';
import { OrderGrid } from 'components/order/orderGrid';
import { OrderCard } from 'components/order/orderCard';
import { useParams } from "react-router-dom";

export const LocationGroup = ({ id }) => {
    const {
        location,
        cash,
        card,
        subsidy,
        orders,
        bonus,
    } = useOrdersByMenuLocationGroup(id);
    console.log(useOrdersByMenuLocationGroup(id));
    const {menuId} = useParams();
    const accepted = useSearchParam('accepted');
    const [fileLoading, setFileLoading] = useState(false);
    const downloadOrders = useApi(
        `/dishlists/${menuId}/print-orders/${location.id}`
    );
    const fileDownloader = useFileDownloader();

    const renderOrders = useCallback(
        orders => {
            return orders.map(order => {
                if (accepted !== undefined && order.accepted != accepted)
                    return null;
                return (
                    <OrderCard key={order.id} locationId={id} order={order} />
                );
            });
        },
        [id, accepted]
    );

    const handleFileDownload = async () => {
        setFileLoading(true);
        const res = await downloadOrders();
        const blob = await res.blob();
        fileDownloader(blob, `orders-${menuId}-${location.title}.pdf`);
        setFileLoading(false);
    };

    return (
        <>
            <ContentBlock>
                <div className={`${styles.locationGroup__header} shaded`}>
                    <div className={styles.locationGroup__headerWing}>
                        <div className={styles.locationGroup__headerGroup}>
                            <LocationTitle
                                className={styles.locationGroup__headerContent}
                                location={location}
                            />
                            <div className={styles.locationGroup__headerCard}>
                                <SumCard
                                    card={card}
                                    cash={cash}
                                    subsidy={subsidy}
                                    bonus={bonus}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.locationGroup__headerWing}>
                        <Button
                            secondary
                            onClick={handleFileDownload}
                            loading={fileLoading}
                        >
                            <Text k={`Download Report`} />
                        </Button>
                    </div>
                </div>
            </ContentBlock>
            <ContentBlock>
                <OrderGrid>{renderOrders(orders)}</OrderGrid>
            </ContentBlock>
        </>
    );
};
