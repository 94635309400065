import styles from './headerDropdown.module.css';
import { Link } from 'react-router-dom';
import { Text } from '../../text';
import { useLogOut } from '../../../hooks/useLogOut';

const HeaderItem = ({ to, href, label, ...rest }) => {
    if (to) {
        return (
            <Link
                className={styles.headerDropdown__dropdownItem}
                to={to}
                {...rest}
            >
                {label}
            </Link>
        );
    } else if (href) {
        return (
            <a
                className={styles.headerDropdown__dropdownItem}
                href={href}
                {...rest}
            >
                {label}
            </a>
        );
    } else {
        return (
            <button className={styles.headerDropdown__dropdownItem} {...rest}>
                {label}
            </button>
        );
    }
};

export const HeaderDropdown = ({ children, items }) => {
    return (
        <div className={styles.headerDropdown}>
            {children}
            <div className={styles.headerDropdown__dropdownWrapper}>
                <ul className={styles.headerDropdown__dropdown}>
                    {items.map((item, index) => {
                        return (
                            <li key={index}>
                                <HeaderItem {...item} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
