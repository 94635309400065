import PropTypes from 'prop-types';
import { Select } from 'components/common/select';
import { useCategories } from 'store/categories/selectors';
import { useTranslator } from 'hooks/useTranslator';
import { useTranslation } from 'store/dictionary/selectors';

const CategorySelect = ({ id, ...rest }) => {
    const categories = useCategories();
    const translator = useTranslator();
    const emptyLabel = useTranslation(`Select a category...`);

    return (
        <Select
            id={id}
            emptyLabel={emptyLabel}
            options={categories.map(({ id, title }) => ({
                key: id,
                label: translator(title),
            }))}
            {...rest}
        />
    );
};

CategorySelect.propTypes = {
    id: PropTypes.string.isRequired,
};

export { CategorySelect };
