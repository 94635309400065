import { ContentBlock, ContentGrid } from 'components/layout/contentGrid';
import { ContentCard } from 'components/layout/contentCard/contentCard';
import { useCategoriesApi } from 'hooks/api/useCategoriesApi';
import { CategoriesTable } from 'components/pages/categories/categoriesTable';
import { Text } from 'components/text';
import { Button } from 'components/common/button';
import { NewCategoryModal } from 'components/pages/categories/newCategoryModal';
import { useDispatch } from 'react-redux';
import { updateNewCategory } from 'store/categories/actions';
import {ErrorBoundary} from "components/common/errorBoudry";

export const CategoriesPage = () => {
    const dispatch = useDispatch();
    useCategoriesApi();

    return (
        <ErrorBoundary boundary="categories">
            <ContentGrid>
                <ContentBlock>
                    <h1 className={`h1 h1_noMargin`}>
                        <Text k={`Categories`} />
                    </h1>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard tinyPadding>
                        <Button
                            secondary
                            onClick={() =>
                                dispatch(updateNewCategory({ active: true }))
                            }
                        >
                            <Text k={`New Category`} />
                        </Button>
                    </ContentCard>
                </ContentBlock>
                <ContentBlock>
                    <ContentCard>
                        <CategoriesTable />
                    </ContentCard>
                </ContentBlock>
            </ContentGrid>
            <NewCategoryModal />
        </ErrorBoundary>
    );
};
