import {
    SET_CSRF_TOKEN,
    SET_LANG,
    SET_MAINTENANCE,
    SET_TOKEN,
} from './constants';
import { RESOLVE_LOADING } from 'store/dictionary/constants';

export const setLang = payload => {
    return {
        type: SET_LANG,
        payload,
    };
};

export const setCsrfToken = payload => {
    return {
        type: SET_CSRF_TOKEN,
        payload,
    };
};

export const setToken = payload => {
    return {
        type: SET_TOKEN,
        payload,
    };
};

export const setMaintenance = status => {
    return {
        type: SET_MAINTENANCE,
        payload: status,
    };
};

export const resolveLoading = key => {
    return {
        type: RESOLVE_LOADING,
        payload: key,
    };
};
