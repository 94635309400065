import { useApiData } from '../useApiData';
import { setCategories } from 'store/categories/actions';
import { useApi } from 'hooks/useApi';
import { useDispatch } from 'react-redux';

export const useCategoriesApi = () => {
    useApiData('/categories', data => setCategories(data.data));
};

export const useCategoriesRefresh = () => {
    const dispatch = useDispatch();
    const getCategories = useApi('/categories');

    return async () => {
        const res = await getCategories();
        dispatch(setCategories((await res.json()).data));
    };
};
