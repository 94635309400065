import { FunctionComponent, ImgHTMLAttributes } from 'react';
import styles from './bankImage.module.css';

interface BankImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

export const BankImage: FunctionComponent<BankImageProps> = ({
    className = '',
    ...rest
}) => {
    return <img className={`${styles.bankImage} ${className}`} {...rest} />;
};
