import { useAppSelector } from 'store/index';

export const useOrdersByMenu = () => {
    return useAppSelector(({ ordersByMenu: { orders } }) => orders);
};

export const useOrdersByMenuExist = () => {
    return useAppSelector(({ ordersByMenu: { orders } }) => !!orders);
};

export const useOrdersByMenuLocations = () => {
    return useAppSelector(({ ordersByMenu: { orders } }) =>
        orders ? orders.map(({ location }) => location) : []
    );
};

export const useOrdersByMenuLocationGroup = (id: string) => {
    return useAppSelector(({ ordersByMenu: { orders } }) =>
        orders?.find(({ location }) => location.id == id)
    );
};

export const useOrdersByMenuLoading = () => {
    return useAppSelector(({ ordersByMenu: { loading } }) => loading);
};

export const useOrdersByMenuUpdating = () => {
    return useAppSelector(({ ordersByMenu: { updating } }) => updating);
};
