import { useAppSelector } from 'store/index';

export const useNewCategory = () => {
    return useAppSelector(
        ({ categories: { new: newCategory } }) => newCategory
    );
};

export const useCategories = () => {
    return useAppSelector(({ categories: { data } }) => data);
};

export const useCategoriesLoading = () => {
    return useAppSelector(({ categories: { loading } }) => loading);
};
