import { Category } from 'store/categories/types';
import { createReducer } from '@reduxjs/toolkit';
import {
    resetNewCategory,
    setCategories,
    setNewCategory,
    updateNewCategory,
} from 'store/categories/actions';

interface New extends Partial<Category> {
    active?: boolean;
}

export type CategoriesReducer = {
    data: Category[];
    loading: boolean;
    new: New;
};

const initialState: CategoriesReducer = {
    data: [],
    loading: true,
    new: { title: {}, active: false, status: true, simple: false },
};

export const categoriesReducer = createReducer(initialState, builder => {
    builder.addCase(setNewCategory, (state, { payload }) => {
        state.new = payload;
    });
    builder.addCase(updateNewCategory, (state, { payload }) => {
        state.new = { ...state.new, ...payload };
    });
    builder.addCase(resetNewCategory, state => {
        state.new = initialState.new;
    });
    builder.addCase(setCategories, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
    });
});
