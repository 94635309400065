import { apiPath } from 'constants/common';
import { FunctionComponent, ImgHTMLAttributes } from 'react';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    size?: {
        height?: number;
        width?: number;
    };
    apiUrl: string;
}

export const Picture: FunctionComponent<Props> = ({
    size = {},
    apiUrl,
    ...rest
}) => {
    const searchParams = new URLSearchParams();
    if (size.width) {
        searchParams.append('width', `${size.width}`);
    }
    if (size.height) {
        searchParams.append('height', `${size.height}`);
    }

    return (
        <picture>
            <source
                srcSet={`${apiPath}/app${apiUrl}?${searchParams.toString()}&format=webp`}
                type={'image/webp'}
            />
            <img
                src={`${apiPath}/app${apiUrl}?${searchParams.toString()}`}
                {...rest}
            />
        </picture>
    );
};
